<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="  Sales Enquiries">
      <router-link
          :to="{ name: 'prospect-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>Create Enquiry</span>
      </router-link>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-2 mr-2">
            <button
                @click="selectTab('open')"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
                :class="{
                'bg-orange-300 text-orange-900': currentTab == 'open',
                'hover:bg-gray-200': currentTab != 'open',
              }"
            >
              <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"/>
              </svg>
              <span>Open Enquiries</span>
            </button>
            <button
                @click="selectTab('closed')"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
                :class="{
                'bg-orange-300 text-orange-900': currentTab == 'closed',
                'hover:bg-gray-200': currentTab != 'closed',
              }"
            >
              <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"/>
              </svg>
              <span>Closed Enquiries</span>
            </button>
            <button
                @click="currentTab = 'search'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
                :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8"/>
                <path d="M21 21l-4.35-4.35"/>
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <input
                  type="text"
                  class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
                  placeholder="Search enquiries by any keyword"
                  title="Enquiries will be searched by: name, address, enquired products, contacts, notes and event names &amp; descriptions"
                  v-model="searchTerm"
                  @keydown.enter="searchEnquiries"
              />
              <button
                  @click="searchEnquiries"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <circle cx="11" cy="11" r="8"/>
                  <path d="M21 21l-4.35-4.35"/>
                </svg>
              </button>
              <!--
                <div class="ml-1 relative">
                  <button
                    @click="searchOptionsOpen = true"
                    class="h-full rounded-full p-2 text-gray-600 hover:text-black transition-colors duration-300 ease-in-out"
                  >
                    <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4 21v-7M4 10V3M12 21v-9M12 8V3M20 21v-5M20 12V3M1 14h6M9 8h6M17 16h6" />
                    </svg>
                  </button>
                  <div
                    v-show="searchOptionsOpen"
                    @click="searchOptionsOpen = false"
                    class="fixed inset-0 z-50"
                  ></div>
                  <transition name="scale-tr">
                    <div
                      v-show="searchOptionsOpen"
                      class="mt-6 mr-4 top-0 right-0 absolute bg-white w-64 shadow-2xl rounded-lg overflow-hidden z-50"
                    >
                      <div class="px-8 py-6">
                        <h2 class="text-center text-xl">SEARCH OPTIONS</h2>
                        <p
                          class="text-center text-gray-700 text-sm"
                        >Check the fields you'd like to search</p>
                        <div class="pt-6">
                          <Toggle
                            label="Notes"
                            :checked="searchOptions.notes"
                            @value-changed="(value) => searchOptions.notes = value"
                          />
                        </div>
                        <div class="pt-6">
                          <Toggle
                            label="Actions"
                            :checked="searchOptions.actions"
                            @value-changed="(value) => searchOptions.actions = value"
                          />
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              -->
            </div>
            <div class="flex justify-end mt-5" v-if="searchOptions">
              <div class="pr-6">
                <Toggle
                    label="Notes"
                    :labelPosition="'left'"
                    :checked="searchOptions.notes"
                    @value-changed="
                    (value) => updateSearchOptions('notes', value)
                  "
                />
              </div>
              <div class>
                <Toggle
                    label="Actions"
                    :labelPosition="'left'"
                    :checked="searchOptions.actions"
                    @value-changed="
                    (value) => updateSearchOptions('actions', value)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mt-5">
          <template v-if="currentTab == 'open'">
            <div v-if="viewState == 'Idle'">
              <vue-good-table
                  :columns="columns"
                  :rows="openProspects"
                  :pagination-options="paginationOpts"
                  :sort-options="{
                  initialSortBy: {
                    field: $store.state.openSortOptions.field,
                    type: $store.state.openSortOptions.type,
                  },
                }"
                  :row-style-class="markRowAsSelected"
                  @on-sort-change="openSortChange"
                  @on-column-filter="openColumnFilter"
                  v-if="viewState == 'Idle' && allProspects.length > 0"
                  styleClass="vgt-table condensed"
                  @on-row-click="onEnquiryRowSelected"
              />
              <h5
                  class="text-muted text-center pt-3 pb-3"
                  v-if="!allProspects || allProspects.length == 0"
              >
                No sales enquiries have been created yet
              </h5>
            </div>
          </template>
          <template v-if="currentTab == 'closed'">
            <div v-if="viewState == 'Idle'">
              <vue-good-table
                  :columns="columns"
                  :rows="closedProspects"
                  :pagination-options="paginationOpts"
                  :sort-options="{
                  initialSortBy: { field: 'enquiry_date', type: 'desc' },
                }"
                  v-if="viewState == 'Idle' && closedProspects.length > 0"
                  styleClass="vgt-table condensed"
                  @on-row-click="onEnquiryRowSelected"
              />

              <h5
                  class="text-muted text-center pt-3 pb-3"
                  v-if="!closedProspects || closedProspects.length == 0"
              >
                No sales enquiries have been closed yet
              </h5>
            </div>
          </template>
          <template v-if="currentTab == 'search'">
            <div>
              <div v-if="searchState == 'Idle'" class="mt-5">
                <h4 class="mb-5">
                  {{ searchResults.length }} search results for '{{
                    searchTermWas
                  }}'
                </h4>
                <vue-good-table
                    :columns="searchResultsColumns"
                    :rows="searchResults"
                    :row-style-class="markRowAsSelected"
                    v-if="searchResults.length > 0"
                    styleClass="vgt-table condensed"
                    @on-row-click="onEnquiryRowSelected"
                />

                <h5
                    class="text-center py-3"
                    v-if="!searchResults || searchResults.length == 0"
                >
                  No results for {{ searchTermWas }}
                </h5>
              </div>
              <div
                  v-if="searchState == 'Searching'"
                  class="flex items-center justify-center py-20"
              >
                <spinner :color="'text-black'" :size="10"/>
              </div>
              <div v-if="searchState == 'Error'">
                <div class="alert alert-danger">
                  Something went wrong performing your search, please try again.
                  If the problem persists, please contact your support team.
                </div>
              </div>
            </div>
          </template>
          <div
              v-if="viewState == 'Loading'"
              class="flex items-center justify-center py-20"
          >
            <spinner :color="'text-black'" :size="10"/>
          </div>
          <div
              v-if="viewState == 'Error'"
              class="flex items-center justify-center py-10"
          >
            <div class="bg-red-200 rounded-lg w-full px-4 py-2">
              <h2 class="text-red-600 text-xl font-bold">
                {{ error.status }}: Error
              </h2>
              <p class="text-red-800 text-lg">
                There has been an error while trying to loading the enquiries,
                please try again later.
                <br/>If the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");

export default {
  name: "salesprospects",
  components: {
    PageHeader,
    Spinner,
    Toggle,
  },
  data() {
    return {
      currentTab: "open",
      viewState: "Idle",
      error: null,
      searchState: "Pending",
      selectedTabIndex: 0,
      hasSearchResults: false,
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      searchResultsColumns: [
        {label: "Name", field: "name"},
        {label: "Status Detail", field: "status_detail"},
        {label: "Product", field: "product_enquiry"},
        {label: "Agent", field: "default_agent.name", width: "150px"},
        {
          label: "Contacted",
          field: "enquiry_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {label: "County", field: "county", width: "150px"},
      ],
      allProspects: [],
      openProspects: [],
      closedProspects: [],
      agentFilters: [],
      statusFilters: [],
      productFilters: [],
      columns: [],
      paginationOpts: {
        enabled: true,
        perPage: 50,
      },
      searchOptionsOpen: false,
    };
  },
  computed: {
    searchOptions: function () {
      return this.$store.state.enquirySearchOptions;
    },
  },
  async mounted() {
    this.$store.state.openSortOptions;
    try {
      this.viewState = "Loading";
      this.allProspects = await this.SalesProspectService.getProspects();

      this.allProspects.forEach((p) => {
        p.product_enquiry =
            p.enquired_products && p.enquired_products.length > 0
                ? p.enquired_products[0].name
                : "";

        if (p.status == "Open") {
          this.openProspects.push(p);
        } else {
          this.closedProspects.push(p);
        }
      });

      this.agentFilters = this.allProspects
          .map((p) => {
            return p.default_agent ? p.default_agent.name : "";
          })
          .filter((val, idx, self) => {
            return self.indexOf(val) === idx && val.length > 0;
          });

      this.statusFilters = this.allProspects
          .map((p) => {
            return p.status_detail;
          })
          .filter((val, idx, self) => {
            return self.indexOf(val) === idx;
          });

      this.productFilters = this.allProspects
          .map((p) => {
            return p.product_enquiry;
          })
          .filter((val, idx, self) => {
            return self.indexOf(val) === idx && val != "";
          });

      this.columns = [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Status Detail",
          field: "status_detail",
          filterOptions: {
            enabled: true,
            placeholder: "All Status Types",
            filterDropdownItems: this.statusFilters,
            filterValue: this.$store.state.openFilterOptions.status_detail,
          },
        },
        {
          label: "Product",
          field: "product_enquiry",
          filterOptions: {
            enabled: true,
            placeholder: "All Products",
            filterDropdownItems: this.productFilters,
            filterValue: this.$store.state.openFilterOptions.product_enquiry,
          },
        },
        {
          label: "Agent",
          field: "default_agent.name",
          filterOptions: {
            enabled: true,
            placeholder: "All Agents",
            filterDropdownItems: this.agentFilters,
            filterValue: this.$store.state.openFilterOptions.default_agent_name,
          },
        },
        {
          label: "Contacted",
          field: "enquiry_date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
          width: "120px",
        },
        {
          label: "Follow-Up",
          field: "next_follow_up",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
          width: "120px",
        },
      ];

      this.searchTerm = this.$store.state.enquiriesSearchTerm;
      await this.searchEnquiries();

      this.viewState = "Idle";
    } catch (err) {
      console.error(err);
      this.error = err;
      this.viewState = "Error";
    }
  },
  methods: {
    selectTab: function (tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setEnquiriesSearchTerm", "");
    },
    sortFollowUp: function (x, y) {
      if (y == null) {
        return -1;
      }

      return x < y ? -1 : x > y ? 1 : 0;
    },
    onEnquiryRowSelected: function (selectedRow) {
      this.$store.dispatch(
          "setLastSelectedEnquiry",
          selectedRow.row.prospect_id
      );
      let prospectId = selectedRow.row.prospect_id;
      this.$router.push({
        name: "prospect-edit",
        params: {prospectId: prospectId},
      });
    },
    markRowAsSelected: function (row) {
      return this.$store.state.lastSelectedEnquiry == row.prospect_id
          ? "bg-green-300"
          : "";
    },
    async searchEnquiries() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setEnquiriesSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let results = await this.SalesProspectService.getProspects(
            this.searchTerm,
            this.searchOptions
        );
        this.searchResults = results.map((r) => {
          return {
            ...r,
            product_enquiry:
                r.enquired_products && r.enquired_products.length > 0
                    ? r.enquired_products[0].name
                    : "",
          };
        });
        this.searchTermWas = this.searchTerm;
        this.searchTerm = "";
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },
    openSortChange(params) {
      this.$store.dispatch("setOpenSortOptions", params[0]);
    },
    openColumnFilter(params) {
      let param = {
        status_detail: params.columnFilters.status_detail,
        default_agent_name: params.columnFilters["default_agent.name"],
        product_enquiry: params.columnFilters.product_enquiry,
      };
      this.$store.dispatch("setOpenFilterOptions", param);
    },
    updateSearchOptions: function (prop, value) {
      let options = this.searchOptions;
      options[prop] = value;
      this.$store.dispatch("setEnquirySearchOptions", options);
    },
  },
};
</script>

<style>
.vgt-table th.filter-th {
  background: white;
}
</style>
